import Parse from "parse";
import ParseConfig from "@/api/parse.config";
import { getFoldersByParent } from "./getFoldersByParent";

export async function getFoldersByType(type, userCourant, typeAbonnement) {
  ParseConfig();
  const ObjectFolderType = Parse.Object.extend("folder_type");
  const ObjectFolder = Parse.Object.extend("folder");
  const ObjectLink = Parse.Object.extend("link");
  const ObjectTheme = Parse.Object.extend("theme");
  const ObjectFile = Parse.Object.extend("file");
  const ObjectCompositeFile = Parse.Object.extend("composite_file");

  const QueryFolderType = new Parse.Query(ObjectFolderType);
  QueryFolderType.equalTo("name", type);
  const QueryFolder = new Parse.Query(ObjectFolder);
  QueryFolder.include("theme");
  QueryFolder.include(["theme.domaine"]);
  const QueryLink = new Parse.Query(ObjectLink);
  const QueryThemes = new Parse.Query(ObjectTheme);
  const QueryFile = new Parse.Query(ObjectFile).limit(5000);
  const QueryCompositeFile = new Parse.Query(ObjectCompositeFile).limit(5000);
  const QueryLinkFromRelation = Parse.User.current()
    .relation("link")
    .query()
    .limit(1000);
  const [
    ResponseFolderType,
    TabFile,
    TabCompositeFile,
    TabLink,
    TabFolder,
    ResponseLinkFromRelation,
  ] = await Promise.all([
    QueryFolderType.first(),
    QueryFile.find(),
    QueryCompositeFile.find(),
    QueryLink.limit(10000).find(),
    new Parse.Query(ObjectFolder).limit(10000).find(),
    QueryLinkFromRelation.find(),
    QueryThemes.limit(10000).find(),
  ]);
  QueryFolder.equalTo("type", ResponseFolderType);
  const ResponseFolders = await QueryFolder.find();
  const folders = [];

  for (const folder of ResponseFolders) {
    const RelationFolderTheme = folder.relation("themes");
    const QueryRelationFolderTheme = RelationFolderTheme.query();
    const ResponseRelationFolderTheme = await QueryRelationFolderTheme.find();

    const TabResponseLink = TabLink.filter(function (e) {
      if (e.attributes.folder !== undefined)
        return e.attributes.folder.id === folder.id;
    });

    const ResponseLink = TabResponseLink[0];
    let deverouille = false;
    if (
      ResponseLinkFromRelation.find((e) => {
        return e.id === ResponseLink.id;
      })
    ) {
      deverouille = true;
    }

    if (
      folder.attributes.name === "Soft skill Pleine Présence" ||
      folder.attributes.name === "Soft skill Intelligence Emotionnelle" ||
      folder.attributes.name === "Soft skill Gestion du Stress" ||
      folder.attributes.name === "Tests psychotechniques : vie personnelle" ||
      folder.attributes.name ===
        "Tests psychotechniques : vie professionnelle" ||
      folder.attributes.name === "Soft skill Pleine Présence (éducation)" ||
      folder.attributes.name ===
        "Soft skill Intelligence Emotionnelle (éducation)" ||
      folder.attributes.name === "Soft skill Gestion du Stress (éducation)"
    ) {
      //recommencer la boucle
      continue;
    }

    if (
      folder.attributes.type &&
      ResponseFolderType &&
      folder.attributes.type.id == ResponseFolderType.id
    ) {
      const mesProtocoles = await getFoldersByParent(
        folder,
        ResponseLinkFromRelation,
        typeAbonnement,
        TabFolder,
        TabLink,
        null,
        TabFile,
        TabCompositeFile
      );

      const foldersTemp = {
        id: folder.id,
        color: folder.attributes.color,
        picto: folder.attributes.picto,
        domaine:
          folder.attributes.theme !== undefined &&
          folder.attributes.theme.attributes.domaine !== undefined
            ? folder.attributes.theme.attributes.domaine.attributes.nom
            : "",
        name: folder.attributes.name,
        text: folder.attributes.text,
        resource: folder.attributes.resource,
        textFinProtocole: folder.attributes.textFinProtocole,
        resourceBg: folder.attributes.resourceBg,
        deverrouillage_etape: folder.attributes.deverrouillage_etape,
        type_abonnement: folder.attributes.type_abonnement,
        link: ResponseLink,
        themes: ResponseRelationFolderTheme,
        themeId: folder.attributes.theme.id,
        protocoles: mesProtocoles,
        deverrouille: deverouille,
        deverrouille_user: deverouille,
      };

      folders.push(foldersTemp);
    }
  }
  console.log('GET FOLDERS BY TYPE!!',folders);
  return folders;
}
