<template>
  <div v-if="showModal" class="modal">
    <div class="modal-box">
      <span class="close" @click="closeModal">&times;</span>

      <div v-if="!isPaymentFailed" class="success-container">
        <i class="fas fa-check-circle success-icon"></i>
        <h1>Paiement validé avec succès !</h1>
        <div class="info">
          <p>
            Merci pour votre confiance. Votre abonnement est maintenant actif.
          </p>
          <p>Un email de confirmation vous a été envoyé.</p>
        </div>

        <div class="summary">
          <h2>Récapitulatif</h2>
          <div class="summary-item">
            <span>Abonnement :</span>
            <span>{{ isEngagementAnnuel ? "Annuel" : "Mensuel" }}</span>
          </div>
          <div class="summary-item">
            <span>Montant :</span>
            <span>{{ formatPrice(getSubscriptionPrice) }}€</span>
          </div>
          <div class="summary-item">
            <span>Date de début :</span>
            <span>{{ formatDate(abonnement.dateDebut) }}</span>
          </div>
          <div class="summary-item">
            <span>Type d'engagement :</span>
            <span>{{ engagementType }}</span>
          </div>
        </div>
      </div>

      <div v-else class="error-container">
        <i class="fas fa-times-circle error-icon"></i>
        <h1>Échec du paiement</h1>
        <div class="info">
          <p>Une erreur est survenue lors du traitement de votre paiement.</p>
          <p>Veuillez réessayer ou contacter notre support.</p>
        </div>
      </div>

      <div class="buttons-continuer">
        <router-link
          v-if="!isPaymentFailed"
          to="/activites"
          class="btn primary"
          @click="closeModal"
        >
          Continuer
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Parse from "parse";
import { setEssaiGratuit } from "@/services/utilisateurs/globalSettingsUser.js";

export default {
  name: "ValidationPaiement",

  data() {
    return {
      showModal: true,
      isEngagementAnnuel: false,
      isPaymentFailed: false,
      abonnement: {
        pulseAnnuel: 0,
        pulseMensuel: 0,
        dateDebut: new Date(),
      },
      isLoading: false,
      error: null,
      validationToken: null,
      isValidRequest: false,
      tokenVerified: false,
    };
  },

  computed: {
    getSubscriptionPrice() {
      return this.isEngagementAnnuel
        ? this.abonnement.pulseAnnuel
        : this.abonnement.pulseMensuel;
    },

    engagementType() {
      return this.isEngagementAnnuel
        ? "Engagement annuel"
        : "Engagement trimestriel";
    },
  },

  methods: {
    closeModal() {
      this.showModal = false;
      this.$router.push("/activites");
    },

    formatDate(date) {
      return new Date(date).toLocaleDateString("fr-FR", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    },

    formatPrice(price) {
      return Number(price).toFixed(2);
    },

    calculateSubscriptionEndDate(isAnnual) {
      const today = new Date();
      return isAnnual
        ? new Date(today.setFullYear(today.getFullYear() + 1))
        : new Date(today.setMonth(today.getMonth() + 3));
    },

    async updateUserSubscription() {
      try {
        if (!this.isValidRequest) {
          throw new Error("Requête non valide");
        }
        console.log(
          "UpdateUserSubscription........",
          this.isEngagementAnnuel,
          this.validationToken
        );
        const result = await Parse.Cloud.run("updateUserSubscription", {
          isEngagementAnnuel: this.isEngagementAnnuel,
          validationToken: this.validationToken,
        });

        // Désactivation de l'essai gratuit car l'utilisateur a souscrit un abonnement
        await setEssaiGratuit(false);

        console.log("result", result);
        return result;
      } catch (error) {
        console.error("Erreur lors de la mise à jour des informations:", error);
        throw error;
      }
    },

    async verifyValidationToken() {
      try {
        const token = this.$route.query.token;
        console.log("Token reçu:", token);

        if (!token) {
          console.error("Token manquant dans l'URL");
          throw new Error("Token de validation manquant");
        }

        // Envoi du token tel quel, sans transformation
        const isValid = await Parse.Cloud.run("verifyPaymentToken", {
          token: token.toString(), // Conversion explicite en string
        });
        console.log("Résultat de la vérification:", isValid);

        if (!isValid) {
          console.error("Token invalide");
          throw new Error("Token de validation invalide");
        }

        this.validationToken = token;
        this.isValidRequest = true;
        this.tokenVerified = true;
        return true;
      } catch (error) {
        console.error("Erreur complète de validation du token:", error);
        this.isPaymentFailed = true;
        this.isValidRequest = false;
        this.tokenVerified = false;
        throw error;
      }
    },

    async getAbonnementDetails() {
      try {
        this.isLoading = true;
        console.log("Début getAbonnementDetails");

        // Vérifier si c'est un retour d'échec
        this.isPaymentFailed = this.$route.query.retourabonnement === "true";
        console.log("isPaymentFailed:", this.isPaymentFailed);

        const currentUser = await Parse.User.current();
        if (!currentUser) {
          throw new Error("Utilisateur non connecté");
        }

        // Vérification du token seulement si ce n'est pas un retour d'échec
        if (!this.isPaymentFailed && !this.tokenVerified) {
          console.log("Vérification du token...");
          await this.verifyValidationToken();
        }

        // Récupération du type d'engagement
        this.isEngagementAnnuel = this.$route.query.engagementAnnuel === "true";
        console.log("isEngagementAnnuel:", this.isEngagementAnnuel);

        // Récupération des paramètres d'abonnement
        const query = new Parse.Query("app_param");
        const abonnementObj = await query.first();

        if (!abonnementObj) {
          throw new Error("Paramètres d'abonnement non trouvés");
        }

        this.abonnement = {
          pulseAnnuel: abonnementObj.get("pulseAnnuel"),
          pulseMensuel: abonnementObj.get("pulseMensuel"),
          dateDebut: new Date(),
        };

        // Mise à jour de l'abonnement si tout est valide
        if (!this.isPaymentFailed && this.isValidRequest) {
          console.log("Mise à jour de l'abonnement...");
          await this.updateUserSubscription();

          // Invalidation du token
          console.log("Invalidation du token...");
          await Parse.Cloud.run("invalidatePaymentToken", {
            token: this.validationToken,
          });
        }
      } catch (error) {
        console.error("Erreur dans getAbonnementDetails:", error);
        this.error = error.message;
        this.isPaymentFailed = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
  async mounted() {
    try {
      await this.getAbonnementDetails();
    } catch (error) {
      console.error("Erreur lors du montage:", error);
      this.error = "Une erreur est survenue lors de l'initialisation";
    }
  },
};
</script>
<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-box {
  background: white;
  border-radius: 10px;
  padding: 2rem;
  max-width: 500px;
  width: 90%;
  text-align: center;
  position: relative;
}

.close {
  position: absolute;
  right: 15px;
  top: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #666;
}

.success-icon {
  color: #4caf50;
  font-size: 3rem;
  margin: 1rem 0;
}

h1 {
  color: #333;
  margin-bottom: 1rem;
}

.info {
  color: #666;
  margin: 1rem 0;
}

.summary {
  background: #f5f5f5;
  padding: 1rem;
  border-radius: 8px;
  margin: 1rem 0;
}

.summary-item {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  border-bottom: 1px solid #ddd;
}

.summary-item:last-child {
  border: none;
}

.buttons-continuer {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}

.btn {
  padding: 0.8rem 2.5rem; /* Augmentation du padding horizontal */
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: 0.3s;
  display: inline-block; /* Ajout pour un meilleur contrôle de la largeur */
}

.primary {
  background: #4caf50;
  color: white;
}

.primary:hover {
  background: #45a049;
}

.error-icon {
  color: #f44336;
  font-size: 3rem;
  margin: 1rem 0;
}

.error {
  background: #f44336;
  color: white;
}

.error:hover {
  background: #d32f2f;
}

@media (max-width: 768px) {
  .btn {
    width: auto; /* Modification pour éviter que le bouton prenne toute la largeur */
    min-width: 200px; /* Largeur minimum pour mobile */
  }
}

.secondary {
  background: #f5f5f5;
  color: #333;
}

@media (max-width: 768px) {
  .buttons-continuer {
    flex-direction: column;
  }

  .btn {
    width: 100%;
    margin: 0.5rem 0;
  }
}
</style>
