import Parse from "parse";

export default async function getMyHeadings() {
  try {
    const response = await Parse.Cloud.run("getMyHeadings");
    const headings = response.headings;
    
    // Log des headings pour debug
    // headings.forEach(heading => {
    //   if (heading.heading) {
    //     console.log("Nom de l'heading:", heading.heading.name);
    //     console.log("Statut:", heading.heading.isEnabled ? "Activé" : "Désactivé");
    //   }
    // });

    return headings;
  } catch (error) {
    console.error("Une erreur s'est produite lors de la récupération des headings:", error);
    throw error;
  }
}