import asyncForEach from "@/services/general/asyncForEach";

export async function getFilesCompositeByModule(
  moduleP,
  TabLink,
  TabCompositeFile
) {
  const compositeFile = {
    expert: [],
    decouverte: [],
  };


  const ResponseLink = TabLink.filter(function (e) {
    if (e.attributes.parent_folder) {
      return e.attributes.parent_folder.id === moduleP.id;
    } else {
      return false;
    }
  });

  await asyncForEach(ResponseLink, async (link) => {
    // console.log('LINK:',link)
    if (link.attributes.composite_file) {
    
      const TabResponseCompositeFile = TabCompositeFile.filter(function (e) {
        return e.id === link.attributes.composite_file.id;
      });
      const ReponseCompositeFile = TabResponseCompositeFile[0];

      if (ReponseCompositeFile && ReponseCompositeFile.attributes.short_file || ReponseCompositeFile && ReponseCompositeFile.attributes.moduleExpert || ReponseCompositeFile && ReponseCompositeFile.attributes.jeux) {
        compositeFile.decouverte.push(link);
      } else if (
        ReponseCompositeFile &&
        ReponseCompositeFile.attributes.long_file
      ) {
        compositeFile.expert.push(link);
      }
    }
  });
  return compositeFile;
}
