import ParseConfig from "@/api/parse.config.js";
import Parse from "parse";

export async function responseQuestionnaryResiliation(questionnaire, group) {
  try {
    ParseConfig();

    // Définition des classes Parse
    const ObjectQuestionnaireResiliation = Parse.Object.extend("questionnaire_resiliation");
    const ObjectUserGroup = Parse.Object.extend("user_group");
    console.log('GROUP:',group)
    // Création des instances
    const questionnaireResiliation = new ObjectQuestionnaireResiliation();
    const queryUserGroup = new Parse.Query(ObjectUserGroup);

    const groupId = group.objectId || group.id;

    // Recherche du groupe utilisateur
    queryUserGroup.equalTo("objectId",groupId);
    const userGroup = await queryUserGroup.first();
    
    if (!userGroup) {
      throw new Error("Groupe utilisateur non trouvé");
    }

    // Configuration du questionnaire
    const questionnaireFields = {
      complexe: questionnaire.complexe || false,
      problemes_techniques: questionnaire.problemes_techniques || false,
      cout: questionnaire.cout || false,
      inutilise: questionnaire.inutilise || false
    };

    // Définition des champs du questionnaire
    Object.entries(questionnaireFields).forEach(([key, value]) => {
      questionnaireResiliation.set(key, value);
    });

    // Gestion du champ "autre" et son texte
    if (questionnaire.autre) {
      questionnaireResiliation.set("autre", questionnaire.texte || "");
    }

    // Association avec le groupe utilisateur
    questionnaireResiliation.set("group", userGroup);

    // Marquer le groupe comme résilié
    userGroup.set("resilie", true);

    // Sauvegarde des modifications
    await Parse.Object.saveAll([userGroup, questionnaireResiliation]);

    return {
      success: true,
      questionnaire: questionnaireResiliation,
      userGroup: userGroup
    };

  } catch (error) {
    console.error("Erreur lors de la résiliation:", error);
    throw error;
  }
}