<template lang="html">
  <div class="is-main-content" style="padding: 5% 0 0 0 !important">
    <div class="is-fullheight accueil" style="margin-top: 50px">
      <div class="container is-fluid">
        <div class="loaderActivite" v-if="loaderFolderActive"></div>
        <b-loading
          :is-full-page="true"
          :active.sync="loaderFolderActive"
          :can-cancel="false"
        >
          <p>
            <b-icon
              pack="fas"
              icon="sync-alt"
              size="is-large"
              custom-class="fa-spin"
              type="is-blue"
            ></b-icon>
          </p>
          <p
            style="
              z-index: 10;
              padding-top: 100px;
              margin-left: -160px;
              color: #4579b2;
            "
          >
            <strong
              v-if="isStepFolder && foldersBrique.length !== 4 && num == 3"
              class="has-text-blue"
            >
              {{ $t("enregsitrementExercicesEnCours") }}
            </strong>
            <strong v-else class="has-text-blue">
              {{ $t("chargementDesInformationsEnCours") }}
            </strong>
          </p>
        </b-loading>
        <!-- <h1 class="title is-size-4">
          {{ $t("personnalisationDeActivitesPro") }}
          <a class="linkPlusInfo" @click="isCardModalIframe = true">
            + d'infos</a
          >
        </h1> -->
        <!-- <div class="columns hidecolumn">
          <div
            class="column is-12-mobile is-12-tablet is-12-desktop is-12-widescreen"
          >
            <div class="columns">
              <div
                class="column is-12-mobile is-12-tablet is-12-desktop is-12-widescreen"
              >
                <div class="box box-information">
                  <div class="columns">
                    <div class="column is-padding-0">
                    </div>
                  </div>
                  <p class="is-margin-b-20 has-text-weight-semibold">
                    {{ $t("") }}
                  </p>
                   <div class="columns">
                    <div class="column is-1 is-padding-1">
                      <img
                        class=""
                        src="../../../assets/img/access.png"
                      />
                    </div>
                    <div class="column is-size-7">
                      <p>{{ $t("paramEspaceAideBloc3") }}</p>
                    </div>
                  </div> 
                </div>
              </div>
            </div>
            <br />
          </div>
        </div> -->

        <div
          style="padding-bottom: 0px"
          v-if="etapes[2].active && foldersProtocolLoad"
        >
          <h1 class="title is-size-5">
            {{ $t("monEspace.GestionAccesExercice") }}
            <!-- <b-tooltip
              size="is-small"
              multilined
              type="is-warning"
              :label="$t('explicationExercice')"
              position="is-right"
            >
              <img
                class="img-bg-icon ombrage"
                src="../../../assets/img/info.png"
              />
            </b-tooltip> -->
          </h1>
          <div class="ExplicationDivAcces">
            <p>
              <i class="fas fa-info-circle"></i> Cliquez sur les différentes
              briques thématiques pour les désactiver ou les réactiver. Quand
              une brique est activée, vous pouvez cliquer sur les parcours
              d’apprentissage souhaités (protocole découverte, protocole expert,
              entraînement libre) pour les désactiver ou les réactiver.
            </p>
          </div>
          <div style="padding: 0rem" class="overflow2">
            <!-- <folders-component
              style="padding: 0rem"
              :foldersBrique="foldersBrique"
              :themes="themes"
              :group="group"
              @unlockProtocol="unlockProtocol"
              @goAbonnement="goAbonnement"
            /> -->
            <PersonnalisationAcces
              :selectedUser="user"
              :forTherapeute="true"
              ref="personnalisationAcces"
            />
            <!-- <div class="fixed-bottom-button">
              <div>
                <p>
                  <i class="fas fa-info-circle"></i> Pour valider les
                  modifications que vous venez d'effectuer, n'oubliez pas de
                  sauvegarder via le bouton ci-dessous.
                </p>
                <button @click="saveInComponent" class="button buttonSaveChanges">
                  Sauvegarder les changements
                </button>
              </div>
            </div> -->
            <div
              class="navbar is-fixed-bottom"
              style="
                position: float;
                padding-top: 10px;
                padding-bottom: 10px;
                border-top: 5px;
                margin-left: 0px;
                background-color: #6753be;
                display: flex;
                justify-content: center;
              "
            >
              <p class="text-navbar">
                <i class="fas fa-info-circle"></i> Pour valider les
                modifications que vous venez d'effectuer, n'oubliez pas de
                sauvegarder via le bouton ci-dessous.
              </p>
              <div>
                <a
                  class="button-mon-profil button button-is-green is-margin-r-20"
                  @click="saveInComponent"
                  type="is-success"
                >
                  Sauvegardez les changements
                </a>
              </div>
            </div>
          </div>
          <!-- <div
            class="navbar is-fixed-bottom is-fullwidth ombrage"
            style="
              position: float;
              padding-top: 20px;
              border-top: 5px;
              background-color: #6753be;
            "
          >
            <div class="navbar-end">
              <a
                class="button is-blue button-sauvegarde"
                @click="saveAndNext(4)"
              >
                {{ $t("sauvegarderEtMaj") }}
              </a>
            </div>
          </div> -->
          <div class="box-valide" v-if="etapes[3].active">
            <h1 class="title is-size-4">
              {{ $t("monEspace.EspaceActiviteValide") }}
            </h1>
            <div
              class="column is-12-mobile is-12-tablet is-12-desktop is-12-widescreen"
            >
              <div class="box columns">
                <div
                  class="column is-1-mobile is-1-tablet is-1-desktop is-1-widescreen"
                >
                  <img
                    class="icon-valide"
                    src="../../../assets/img/check.svg"
                    style="fill: #000"
                  />
                </div>
                <div
                  class="column is-12-mobile is-11-tablet is-11-desktop is-11-widescreen"
                >
                  <p>
                    {{ $t("messageFinalMonEspace") }}
                    <router-link
                      to="/utilisateurs"
                      class="button is-cyan is-margin-b-20"
                    >
                      {{ $t("ajoutUtilisateur") }}&nbsp;&nbsp;
                      <b-icon pack="fas" icon="plus" size=""></b-icon>
                    </router-link>
                  </p>
                </div>
              </div>
              <div
                class="navbar is-fixed-bottom is-fullwidth ombrage column is-12-mobile is-12-tablet is-12-desktop is-12-widescreen"
                style="
                  position: float;
                  padding: 20px;
                  border-top: 5px;
                  background-color: #6753be;
                "
              >
                <div class="navbar-end">
                  <a class="button retourEspaceGestion" @click="saveAndNext(4)">
                    <span class="versactivites">
                      {{ $t("continuerDecouverteEspaceGestion") }}
                    </span>
                    <i class="fas fa-home fa-2x"></i>
                  </a>
                  <a
                    class="button is-warning retourEspacePersonnalisation"
                    @click="saveAndNext(0)"
                  >
                    <span class="versactivites">
                      {{ $t("revenirEtapePersonnalisation") }}
                    </span>
                    <img
                      class="icon"
                      src="../../../assets/img/parameters-white.svg"
                    />
                  </a>
                  <a
                    class="button is-blue button-sauvegarde decouvrirEspaceActivite"
                    :href="urlFront"
                  >
                    <span class="versactivites">
                      {{ $t("decouvrirEspaceActivite") }}
                    </span>
                    <img
                      src="../../../assets/img/acces_front_cabinet_blanc_001_160519@2x.png"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      :width="1200"
      :active.sync="isCardModalIframe"
      @close="isCardModalIframe = false"
    >
      <modal-iframe-component
        url="https://www.symbiocenter.fr/2019/09/12/decouvrir-mon-espace-manager/"
      />
    </b-modal>
  </div>
</template>

<script>
import ParseConfig from "@/api/parse.config.js";
import Parse from "parse";
import { linkToAddToUser } from "@/services/folders/linkToAddToUser.js";
import { Chrome } from "vue-color/";
import { createNamespacedHelpers } from "vuex";
import codeGenerator from "@/services/general/codeGenerator.js";
import defineSteps from "@/services/espace/defineSteps.js";
import cloneDeep from "lodash/cloneDeep";
import foldersComponent from "./folders.vue";
import { unlockFolder } from "@/services/folders/unlockFolder.js";
import { saveVerrouillageEtapeByProtocoleAndUser } from "@/services/folders/saveVerrouillageEtapeByProtocoleAndUser";
import { pushLinkToUser } from "@/services/folders/pushLinkToUser";
import router from "@/router";
import ModalIframe from "./modalInformation.vue";
import { URL_FRONT } from "@/configuration/urlConf.js";
const User = createNamespacedHelpers("user");
import PersonnalisationAcces from "./utilisateur/PersonnalisationAcces";

export default {
  name: "mes-activites",
  components: {
    "chrome-picker": Chrome,
    FoldersComponent: foldersComponent,
    ModalIframeComponent: ModalIframe,
    PersonnalisationAcces,
  },
  data() {
    return {
      image: {},
      color: { hex: "#4579B2" },
      editorOption: {
        placeholder: "",
        theme: "snow",
      },
      file: null,
      isCardModalIframe: false,
      urlFront: URL_FRONT,
      etapes: [
        {
          etape: 1,
          active: false,
          complete: false,
        },
        {
          etape: 2,
          active: false,
          complete: false,
        },
        {
          etape: 3,
          active: true,
          complete: false,
        },
        {
          etape: 4,
          active: false,
          complete: false,
        },
        {
          etape: 5,
          active: false,
          complete: false,
        },
      ],
      textures: {
        type_texture: [],
        type_background: [],
      },
      textureSelectionnee: {},
      userGroup: {},
      error: false,
      foldersBrique: [],
      themes: [],
      foldersProtocolLoad: false,
      linksToAdd: [],
      hasImage: false,
      image2: null,
      imageTexture: null,
      imageBackground: null,
      isCardAjoutTexture: false,
      isCardAjoutBackground: false,
      loaderFolderActive: false,
      isStepFolder: false,
      num: 0,
      refresh: false,
    };
  },
  created() {
    //console.log('UserForCustom',this.user)
    this.selectedUserForCustomization = this.user;
  },
  computed: {
    ...User.mapGetters(["group", "user", "folders"]),
  },
  methods: {
    ...User.mapActions({
      actionsUpdateUserGroup: "updateUserGroup",
      actionsLogOut: "logOut",
      actionsLoadFolder: "loadFolder",
    }),
    async goAbonnement() {
      await this.saveAndNext();
      this.$router.push("abonnement");
    },
    async goToActivites() {
      await this.actionsLogOut();
      window.location.href = this.urlFront;
    },
    selectTexture(texture) {
      if (this.textureSelectionnee.id === texture.id) {
        this.textureSelectionnee = {};
        return;
      }
      this.textureSelectionnee = {
        id: texture.id,
        name: texture.name,
      };
    },
    async backStep(num) {
      this.num = num;
      this.error = false;
      let prepareLoadLink = false;
      if (!this.userGroup.name) {
        this.error = true;
        return;
      }
      await this.actionsUpdateUserGroup();
      this.initialisationUserGroup(this.group);
      this.foldersBrique = cloneDeep(
        this.folders.filter((e) => e.domaine == "Santé")
      );
      this.loaderFolderActive = true;
      try {
        const ObjectUserGroup = Parse.Object.extend("user_group");
        const ObjectUser = Parse.Object.extend("User");
        const ObjectTexture = Parse.Object.extend("texture");

        const QueryUserGroup = new Parse.Query(ObjectUserGroup);
        const QueryUser = new Parse.Query(ObjectUser);
        const QueryTexture = new Parse.Query(ObjectTexture);

        QueryUserGroup.equalTo("objectId", this.userGroup.objectId);
        //const ResponseUserGroup = await QueryUserGroup.first();

        QueryUser.equalTo("objectId", this.user.objectId);
        const ResponseUser = await QueryUser.first();
        if (this.textureSelectionnee.id) {
          QueryTexture.equalTo("objectId", this.textureSelectionnee.id);
          //const ResponseTexture = await QueryTexture.first();
          //ResponseUserGroup.set("texture", ResponseTexture);
        }
        if (this.isStepFolder) {
          this.linksToAdd = await this.linkToAddToUser();
          saveVerrouillageEtapeByProtocoleAndUser(
            this.foldersBrique,
            await Parse.User.current()
          );
          await pushLinkToUser(ResponseUser, this.linksToAdd);
          prepareLoadLink = true;
          this.isStepFolder = false;
        }
        if (num === 2) {
          this.isStepFolder = true;
        }

        if (num || num === 0) {
          if (num === 4) {
            router.push("/accueil");
          } else if (num === 3) {
            defineSteps(this.etapes, num);
          } else {
            defineSteps(this.etapes, num);
          }
        }
        if (this.isStepFolder && this.foldersBrique.length !== 4) {
          this.loaderFolderActive = false;
          return;
        }
        if (prepareLoadLink) {
          await this.actionsLoadFolder();
        }
        this.loaderFolderActive = false;
      } catch (error) {
        this.$buefy.toast.open({
          duration: 5000,
          message: "Une erreur est survenue.",
          position: "is-top",
          type: "is-danger",
        });
        this.loaderFolderActive = false;
      }
    },
    async getTexture() {
      this.textures.type_background = [];
      this.textures.type_texture = [];
      ParseConfig();
      const Texture = Parse.Object.extend("texture");
      const query = new Parse.Query(Texture);
      const textures = await query.find();
      textures.forEach((texture) => {
        if (texture.attributes.texture) {
          const newTexture = {
            id: texture.id,
            name: texture.attributes.name,
            url: texture.attributes.texture._url,
          };
          if (texture.attributes.type_texture === "background") {
            newTexture.urlTransform =
              "url(" + texture.attributes.texture._url + ")";
            this.textures.type_background.push(newTexture);
          } else if (texture.attributes.type_texture === "texture") {
            newTexture.urlTransform =
              "url(" + texture.attributes.texture._url + ")";
            this.textures.type_texture.push(newTexture);
          }
        }
      });
    },
    setImage: function (output) {
      this.hasImage = true;
      this.image2 = output;
      this.userGroup.logo = {};
      this.userGroup.logo.url = this.image2.dataUrl;
      this.userGroup.logo.name = this.image2.name;
      this.userGroup.logo.logo = this.image2;
    },
    setImageTexture: function (output) {
      this.imageTexture = null;
      this.imageTexture = output;
    },
    setImageBackground: function (output) {
      this.imageBackground = null;
      this.imageBackground = output;
    },
    async unlockProtocol(props) {
      await unlockFolder(props, this.group.type_abonnement, true);
    },
    async addTexture() {
      const ObjectTexture = Parse.Object.extend("texture");
      const NewTexture = new ObjectTexture();
      NewTexture.set("type_texture", "texture");
      NewTexture.set("name", this.imageTexture.name);
      var base64 = this.imageTexture.dataUrl.split("base64,")[1];
      var parseFile = new Parse.File(this.imageTexture.name, {
        base64: base64,
      });
      const fileSave = await parseFile.save();
      NewTexture.set("texture", fileSave);
      await NewTexture.save();
      this.isCardAjoutTexture = false;
      this.imageTexture = false;
      await this.getTexture();
    },
    async addBackground() {
      const ObjectTexture = Parse.Object.extend("texture");
      const NewTexture = new ObjectTexture();
      NewTexture.set("type_texture", "background");
      NewTexture.set("name", this.imageBackground.name);
      var base64 = this.imageBackground.dataUrl.split("base64,")[1];
      var parseFile = new Parse.File(this.imageBackground.name, {
        base64: base64,
      });
      const fileSave = await parseFile.save();
      NewTexture.set("texture", fileSave);
      await NewTexture.save();
      this.isCardAjoutBackground = false;
      this.imageBackground = false;
      await this.getTexture();
    },
    initialisationUserGroup(monGroup) {
      this.userGroup = Object.assign(monGroup);
      if (this.userGroup.logo && this.userGroup.logo._name) {
        this.userGroup.logo.name = this.userGroup.logo._name;
        this.image = cloneDeep(this.userGroup.logo);
      } else if (this.userGroup.logo && this.userGroup.logo.name) {
        this.image = cloneDeep(this.userGroup.logo);
      }
      if (this.userGroup.texture && this.userGroup.texture.objectId) {
        this.textureSelectionnee.id = this.userGroup.texture.objectId;
      } else if (this.userGroup.texture && this.userGroup.texture.id) {
        this.textureSelectionnee.id = this.userGroup.texture.id;
      }
      if (!this.userGroup.mode_retrocession) {
        this.userGroup.mode_retrocession = "Credit";
      }
      const texture =
        this.textures.type_background.find((texture) => {
          return texture.id === this.textureSelectionnee.id;
        }) ||
        this.textures.type_texture.find((texture) => {
          return texture.id === this.textureSelectionnee.id;
        });
      if (texture) {
        this.textureSelectionnee.name = texture.name;
      }
      if (this.userGroup.couleur) {
        this.color.hex = this.userGroup.couleur;
      }
      if (!this.userGroup.code_affiliation) {
        this.userGroup.code_affiliation = codeGenerator("xxxxxxxx");
      }
    },
    saveInComponent() {
      this.$refs.personnalisationAcces.saveChanges();
    },
    async saveAndNext(num) {
      this.num = num;
      this.error = false;
      let prepareLoadLink = false;
      if (!this.userGroup.name) {
        this.error = true;
        return;
      }
      this.loaderFolderActive = true;
      try {
        const ObjectUserGroup = Parse.Object.extend("user_group");
        const ObjectUser = Parse.Object.extend("User");
        const ObjectTexture = Parse.Object.extend("texture");

        const QueryUserGroup = new Parse.Query(ObjectUserGroup);
        const QueryUser = new Parse.Query(ObjectUser);
        const QueryTexture = new Parse.Query(ObjectTexture);

        QueryUserGroup.equalTo("objectId", this.userGroup.objectId);
        const ResponseUserGroup = await QueryUserGroup.first();

        QueryUser.equalTo("objectId", this.user.objectId);
        const ResponseUser = await QueryUser.first();
        if (this.textureSelectionnee.id) {
          QueryTexture.equalTo("objectId", this.textureSelectionnee.id);
          const ResponseTexture = await QueryTexture.first();
          ResponseUserGroup.set("texture", ResponseTexture);
        }

        if (this.isStepFolder) {
          try {
            this.linksToAdd = await linkToAddToUser(this.foldersBrique);
          } catch (e) {}
          /*saveVerrouillageEtapeByProtocoleAndUser(
            this.foldersBrique,
            await Parse.User.current()
          );*/

          await pushLinkToUser(ResponseUser, this.linksToAdd);

          prepareLoadLink = true;
          this.isStepFolder = false;
        }
        if (num === 2) {
          this.isStepFolder = true;
        }

        ResponseUserGroup.set("name", this.userGroup.name);
        ResponseUserGroup.set("description", this.userGroup.description);
        if (
          this.userGroup.logo &&
          this.userGroup.logo.logo &&
          this.userGroup.logo.name &&
          this.userGroup.logo.name !== this.image.name
        ) {
          this.image.name = this.userGroup.logo.name;
          var base64 = this.userGroup.logo.url.split("base64,")[1];
          var parseFile = new Parse.File("logo", {
            base64: base64,
          });
          const fileSave = await parseFile.save();
          ResponseUserGroup.set("logo", fileSave);
        }
        ResponseUserGroup.set("couleur", this.color.hex);
        ResponseUserGroup.set(
          "mode_retrocession",
          this.userGroup.mode_retrocession
        );
        ResponseUserGroup.set(
          "code_affiliation",
          this.userGroup.code_affiliation
        );
        if (num || num === 0) {
          if (num === 4) {
            ResponseUserGroup.set("parametrage_fait", true);
            await ResponseUserGroup.save();
            await ResponseUser.save();
            await this.actionsUpdateUserGroup();
          } else if (num === 3) {
            defineSteps(this.etapes, num);
            ResponseUserGroup.set("parametrage_fait", true);
            await ResponseUserGroup.save();
            await ResponseUser.save();
            await this.actionsUpdateUserGroup();
          } else {
            defineSteps(this.etapes, num);
            await ResponseUserGroup.save();
            await ResponseUser.save();
            await this.actionsUpdateUserGroup();
          }
        } else {
          await ResponseUserGroup.save();
          await ResponseUser.save();
        }
        if (this.isStepFolder && this.foldersBrique.length !== 4) {
          this.loaderFolderActive = false;
          return;
        }
        if (prepareLoadLink) {
          await this.actionsLoadFolder();
        }
        this.loaderFolderActive = false;
        this.refresh = true;
      } catch (error) {
        this.$buefy.toast.open({
          duration: 5000,
          message: "Une erreur est survenue.",
          position: "is-top",
          type: "is-danger",
        });
        this.loaderFolderActive = false;
      }
    },
  },
  async mounted() {

    this.loaderFolderActive = true;
    await this.getTexture();
    if (this.folders === null) await this.actionsLoadFolder();

    this.foldersBrique = cloneDeep(
      this.folders.filter((e) => e.domaine == "Santé")
    );

    const oDomaine = Parse.Object.extend("domaine");
    const queryDomaine = new Parse.Query(oDomaine);
    queryDomaine.equalTo("nom", "Santé");
    const domaineSante = await queryDomaine.first();
    const oTheme = Parse.Object.extend("theme");
    const queryTheme = new Parse.Query(oTheme);
    queryTheme.equalTo("domaine", domaineSante);
    queryTheme.ascending("ordre");
    this.themes = await queryTheme.find();
    this.foldersProtocolLoad = true;
    this.initialisationUserGroup(this.group);
    this.loaderFolderActive = false;

    this.error = false;
    let prepareLoadLink = false;
    if (!this.userGroup.name) {
      this.error = true;
      return;
    }
    this.loaderFolderActive = true;
    try {
      const ObjectUserGroup = Parse.Object.extend("user_group");
      const ObjectUser = Parse.Object.extend("User");
      const ObjectTexture = Parse.Object.extend("texture");

      const QueryUserGroup = new Parse.Query(ObjectUserGroup);
      const QueryUser = new Parse.Query(ObjectUser);
      const QueryTexture = new Parse.Query(ObjectTexture);

      QueryUserGroup.equalTo("objectId", this.userGroup.objectId);
      const ResponseUserGroup = await QueryUserGroup.first();

      QueryUser.equalTo("objectId", this.user.objectId);
      const ResponseUser = await QueryUser.first();
      if (this.textureSelectionnee.id) {
        QueryTexture.equalTo("objectId", this.textureSelectionnee.id);
        const ResponseTexture = await QueryTexture.first();
        ResponseUserGroup.set("texture", ResponseTexture);
      }

      if (this.isStepFolder) {
        this.linksToAdd = await this.linkToAddToUser();
        /*saveVerrouillageEtapeByProtocoleAndUser(
            this.foldersBrique,
            await Parse.User.current()
          );*/

        await pushLinkToUser(ResponseUser, this.linksToAdd);

        prepareLoadLink = true;
        this.isStepFolder = false;
      }

      this.isStepFolder = true;

      ResponseUserGroup.set("name", this.userGroup.name);
      ResponseUserGroup.set("description", this.userGroup.description);
      if (
        this.userGroup.logo &&
        this.userGroup.logo.logo &&
        this.userGroup.logo.name &&
        this.userGroup.logo.name !== this.image.name
      ) {
        this.image.name = this.userGroup.logo.name;
        var base64 = this.userGroup.logo.url.split("base64,")[1];
        var parseFile = new Parse.File("logo", {
          base64: base64,
        });
        const fileSave = await parseFile.save();
        ResponseUserGroup.set("logo", fileSave);
      }
      ResponseUserGroup.set("couleur", this.color.hex);
      ResponseUserGroup.set(
        "mode_retrocession",
        this.userGroup.mode_retrocession
      );
      ResponseUserGroup.set(
        "code_affiliation",
        this.userGroup.code_affiliation
      );
      if (2 || 2 === 0) {
        defineSteps(this.etapes, 2);
        await ResponseUserGroup.save();
        await ResponseUser.save();
        await this.actionsUpdateUserGroup();
      }
      if (this.isStepFolder && this.foldersBrique.length !== 4) {
        this.loaderFolderActive = false;
        return;
      }
      if (prepareLoadLink) {
        await this.actionsLoadFolder();
      }
      this.loaderFolderActive = false;
    } catch (error) {
      this.$buefy.toast.open({
        duration: 5000,
        message: "Une erreur est survenue.",
        position: "is-top",
        type: "is-danger",
      });
      this.loaderFolderActive = false;
    }
  },
  watch: {
    refresh() {
      if (this.refresh) {
        window.location.reload(); // refresh page
      }
    },
    imageTexture() {
      if (this.imageTexture) {
        this.isCardAjoutTexture = true;
      }
    },
    imageBackground() {
      if (this.imageBackground) {
        this.isCardAjoutBackground = true;
      }
    },
  },
};
</script>

<style>
.steps .step-item.is-active .step-marker {
  color: #239380 !important;
  border-color: #239380 !important;
}

.steps .step-item.is-completed .step-marker {
  background-color: #239380 !important;
}

.steps.is-large .is-active:not(:first-child)::before {
  background: #239380 !important;
}
.steps.is-large .is-completed:not(:first-child)::before {
  background: #239380 !important;
}

.step-detailsDesc {
  font-size: medium;
  background-color: #239380;
  color: white;
  padding: 5px;
  border-radius: 9px;
}
.steps.is-large .step-item .step-details {
  margin-top: 4rem;
}

.cacherDetails {
  display: none;
}

.retourEspaceGestion {
  background-color: #b2b2b2;
  color: white;
  margin: 15px;
  padding: 30px;
  white-space: normal;
}
.retourEspacePersonnalisation {
  margin: 15px;
  padding: 30px;
  white-space: normal;
}

.decouvrirEspaceActivite {
  white-space: normal;
  padding: 30px;
  margin: 15px;
}

.decouvrirEspaceActivite img {
  width: 36px;
  margin-left: 20px;
}

.retourEspacePersonnalisation img {
  width: 306px;
  margin-left: 20px;
}

.retourEspaceGestion label {
  width: 250px;
}
.loaderActivite {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Styles pour votre loader ici */
}

.ExplicationDivAcces {
  width: 80%;
}

.fixed-bottom-button {
  position: fixed;
  bottom: 0;
  width: 80%;
  text-align: center; /* Optionnel : pour centrer le bouton */
  background-color: white; /* Optionnel : pour une meilleure visibilité */
  padding: 1rem; /* Optionnel : ajuster le padding si nécessaire */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Optionnel : pour un peu de style */
}

.buttonSaveChanges {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  background-color: #239380;
  color: white;
}

.text-navbar {
  margin-right: 20px;
  color: white;
}

.navbar-end {
  display: flex;
  justify-content: space-between;
}
</style>
