// authService.js
import Parse from 'parse';
import ParseConfig from "@/api/parse.config";

const AuthService = {
  async generateToken(userId) {
    ParseConfig();
    try {
      console.log("Test connexion..");
      // Appel de la fonction Cloud pour générer le lien de connexion
      const response = await Parse.Cloud.run("generateLoginToken", {
        userId,
      });

      // Récupération du lien généré
      const loginLink = response.link;

      // Afficher le lien ou l'envoyer par email via un service backend
      console.log("Lien de connexion généré:", loginLink);

      return loginLink;
      // Vous pouvez ici envoyer le lien par email à l'utilisateur via votre backend ou API mail
    } catch (error) {
      console.error(
        "Erreur lors de la génération du lien de connexion:",
        error.message
      );
    }
  },

  recupToken() {
    try {
      // Vérifier si window.location est défini
      if (!window || !window.location) {
        console.error("L'objet window ou window.location n'est pas accessible");
        return null;
      }
  
      // Récupérer l'URL complète incluant les fragments (après le #)
      const hash = window.location.hash;
      if (!hash) {
        console.error("Aucun fragment (#) trouvé dans l'URL");
        return null; 
      }
  
      const hashParts = hash.split("?");
      if (hashParts.length < 2) {
        console.error("Format d'URL invalide: pas de paramètres après le #");
        return null;
      }
  
      const hashParams = hashParts[1];
  
      // Si des paramètres existent après le #
      if (hashParams && hashParams.trim() !== "") {
        try {
          const urlParams = new URLSearchParams(hashParams);
          const token = urlParams.get("token");
          
          if (!token || token.trim() === "") {
            console.error("Token vide ou invalide");
            return null;
          }
  
          console.log("Token récupéré:", token);
          
          // Vérifier que getSessionToken existe
          if (typeof this.getSessionToken !== "function") {
            console.error("La méthode getSessionToken n'est pas définie");
            return null;
          }
  
          try {
            var response = this.getSessionToken(token);
            if (!response) {
              console.error("La réponse de getSessionToken est vide");
              return null;
            }
            return response;
          } catch (sessionError) {
            console.error("Erreur lors de la récupération de la session:", sessionError);
            return null;
          }
  
        } catch (paramsError) {
          console.error("Erreur lors du parsing des paramètres:", paramsError);
          return null;
        }
      } else {
        console.error("Aucun paramètre trouvé après le #");
        return null;
      }
    } catch (error) {
      console.error("Erreur générale dans recupToken:", error);
      return null;
    }
  },

//   async authenticateUser(token) {
//     ParseConfig();

//     try {
//       // Appel de la fonction Cloud pour authentifier l'utilisateur avec le token
//       const response = await Parse.Cloud.run("authenticateWithToken", {
//         token,
//       });
//       console.log("Réponse reçue:", response);
//       const tokenResponse = response.sessionToken;

//       // Chaînage des fonctions asynchrones
//       await Parse.User.logOut();
//       console.log("Utilisateur déconnecté.");
//       console.log("Authentification de l'utilisateur avec le token...");
//       console.log("Token:", tokenResponse);
//       await Parse.User.become(tokenResponse);
//       console.log("Utilisateur authentifié avec succès.");
//       console.log("Utilisateur actuel:", Parse.User.current());
//     } catch (error) {
//       console.error(
//         "Erreur lors de l'authentification de l'utilisateur:",
//         error.message
//       );
//     }
//   },

  async getSessionToken(token) {
    ParseConfig();
    try {
        console.log("GetSESSIONTOKEN..", token);
        // Appel de la fonction Cloud pour authentifier l'utilisateur avec le token
        const response = await Parse.Cloud.run("authenticateWithToken", {
          token,
        });
        console.log("Réponse reçue:", response);
        const tokenResponse = response.sessionToken;
        const MailResponse = response.mail;
        return { tokenResponse, MailResponse };

    }
    catch (error) {
      console.error(
        "Erreur lors de l'authentification de l'utilisateur:",
        error.message
      );
    }
  }
};

export default AuthService;
