import asyncForEach from "@/services/general/asyncForEach";

export async function getFilesByModule(
  moduleP,
  typeAbonnement,
  TabFile,
  TabLink
) {
  const files = [];


  const ResponseLink = TabLink.filter(function (e) {
    if (e.attributes.parent_folder) {
      return e.attributes.parent_folder.id === moduleP.id;
    } else {
      return false;
    }
  });

  await asyncForEach(ResponseLink, async (link) => {
    if (link.attributes.file) {
      /*const QueryFile = new Parse.Query(ObjectFile);
      QueryFile.equalTo("objectId", link.attributes.file.id);
      const ReponseFile = await QueryFile.first();*/
      const TabResponseFile = TabFile.filter(function (e) {
        return e.id === link.attributes.file.id;
      });
      const ReponseFile = TabResponseFile[0];

      if (typeAbonnement === "play") {
        if (!ReponseFile.attributes.avecCapteur) {
          files.push(ReponseFile);
        }
      } else {
        files.push(ReponseFile);
      }
    }
  });
  return files;
}
