import Parse from "parse";
import ParseConfig from "@/api/parse.config";

export async function getSuiviForUser(userId) {
  ParseConfig();
  var User = Parse.Object.extend("User");
  var queryUser = new Parse.Query(User);
  queryUser.equalTo("objectId", userId);
  var result = await queryUser.find();
  var fileType = Parse.Object.extend("file_type");
  var queryFileType = new Parse.Query(fileType);
  queryFileType.find();

  var FileDone = Parse.Object.extend("file_done");
  var queryFileDone = new Parse.Query(FileDone);
  queryFileDone.limit(500);
  queryFileDone.equalTo("user", result[0]);
  queryFileDone.include("file");
  queryFileDone.include("protocole");
  queryFileDone.include("module");
  var resultFileDone = await queryFileDone.find();
  var Link = Parse.Object.extend("link");
  var queryLink = new Parse.Query(Link);
  var Folder = Parse.Object.extend("folder");
  var queryFolder = new Parse.Query(Folder);
  
  var fileObjects = {};
  for (const element of resultFileDone) {
    var LastDate = element.updatedAt;
    var ParentFolder = [];
    var timeElapsed = element.attributes.time_elapsed;
    var Ani_Boolean = element.attributes.ANI;

    if (element.get("file") === undefined) {
      continue;
    }
    var fileId = element.get("file").id;
    var protocole = element.get("protocole");
    if (protocole == undefined) {
      continue;
    }
    queryFolder.equalTo("objectId", protocole.id);
    queryLink.matchesQuery("folder", queryFolder);
    queryLink.include("parent_folder");
    var resultLink = await queryLink.find();
    for (const linkElement of resultLink) {
      ParentFolder.push(linkElement);
    }
    var module = element.get("module");
    var file = element.get("file");

    if (file && file.attributes.type && file.attributes.type.attributes.name) {
      var typeExercice = file.attributes.type.attributes.name;
    }

    // Créer un objet résultat avec date et score si résultatJeux existe
    let resultatJeuxObj = null;
    if (element.attributes.resultatJeux) {
      resultatJeuxObj = {
        date: LastDate,
        score: element.attributes.resultatJeux,
        timeElapsed: timeElapsed,
      };
    }

    if (fileObjects[fileId]) {
      fileObjects[fileId].count++;
      fileObjects[fileId].timeElapsed += timeElapsed;
      if (LastDate > fileObjects[fileId].LastDate) {
        fileObjects[fileId].LastDate = LastDate;
      }
      // Ajouter le nouveau résultat au tableau s'il existe
      if (resultatJeuxObj) {
        if (!fileObjects[fileId].resultatJeuxHistorique) {
          fileObjects[fileId].resultatJeuxHistorique = [];
        }
        fileObjects[fileId].resultatJeuxHistorique.push(resultatJeuxObj);
      }
    } else {
      fileObjects[fileId] = {
        count: 1,
        file: file,
        protocole: ParentFolder[0]
          ? ParentFolder[0].attributes.parent_folder
          : file.objectId,
        module: module,
        timeElapsed: timeElapsed,
        LastDate: LastDate,
        typeExercice: typeExercice,
        Ani_Boolean: Ani_Boolean,
        resultatQuestionnaire: element.attributes.resultatQuestionnaire || null,
        resultatJeux: element.attributes.resultatJeux || null,
        resultatJeuxHistorique: resultatJeuxObj ? [resultatJeuxObj] : []
      };
    }
  }
  return fileObjects;
}