import store from "@/store/store";
import Router from "vue-router";
import Vue from "vue";

import Root from "@/views/Root.vue";
import Connexion from "@/views/NewConnexion.vue";
// import Connexion from "@/views/Connexion.vue";

import ConnexionDev from "@/views/ConnexionDev.vue";
import ResetPassword from "@/views/ResetPassword.vue";
// import Inscription from "@/views/Inscription.vue";
import InscriptionCredits from "@/views/InscriptionCredits.vue";
// import InscriptionIframe from "@/views/InscriptionIframe.vue";
// import InscriptionEssaiPulse from "@/views/InscriptionEssaiPulse.vue";

import InscriptionSucceed from "@/views/InscriptionSucceed.vue";
import HeaderTest from "@/components/HeaderTest.vue";
import InscriptionOffrePlay from "@/views/InscriptionOffrePlay.vue";
import InscriptionOffrePulse from "@/views/InscriptionOffrePulse.vue";
import AccountConfirm from "@/views/AccountConfirm.vue";
import VisioConfirm from "@/views/VisioConfirm.vue";
import VisioRefuse from "@/views/VisioRefuse.vue";
import AcceptSuivi from "@/views/AcceptSuivi.vue";
import Accueil from "@/views/SymbioCenter/AccueilNew.vue";
import MessageNotification from "@/views/MessageNotification.vue";

import Home from "@/components/Home.vue";
import MonEspace from "@/components/MonEspace";
import Activites from "@/components/Activites";
import Utilisateurs from "@/components/Utilisateurs";
import Abonnement from "@/components/NewAbonnement";

import NewAbonnement from "./components/espaceGestionV2/abonnement.vue/NewAbonnement.vue";
// import Abonnement from "@/components/Abonnement";

import Actualites from "@/components/Actualites";
import Retrocession from "@/components/Retrocession";
import SeanceGroupe from "@/components/SeanceGroupe";
import MonProfil from "@/components/MonProfil";
import MonAni from "@/components/MonAni";
// import MonAni from "@/components/ani/aniChart.vue";
import Messagerie from "@/components/chat/Messagerie";
import Support from "@/components/Support";
import MaConfiguration from "@/components/MaConfiguration";
import TableauDeBord from "@/components/TableauDeBord";
import ValidationPaiement from "@/components/abonnement/validationpaiement";
import NewsletterDesinscription from "@/components/newsletter/NewsletterDesinscription";
import meetingComponent from "@/components/visio/meetingComponent.vue";
import Forum from "@/components/forum/ForumComponent.vue";

import LoginRequired from "@/loginRequired";
import isTherapeute from "@/isTherapeute";
import NewVersionEnabled from "./NewVersionEnabled";
import CguRequired from "@/cguRequired";
import DroitAcces from "@/droitAcces";

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/connexion",
      name: "Connexion",
      component: Connexion,
      beforeEnter: (to, from, next) => {
        if (LoginRequired(store) && isTherapeute(store) && !NewVersionEnabled(store)) {
          next("/utilisateurs");
        } else if (LoginRequired(store) && !isTherapeute(store) || LoginRequired(store) && NewVersionEnabled(store)) {
          next("/activites");
        } else {
          next();
        }
      },
    },
    {
      path: "/devSymbiofi",
      name: "DevSymbiofi",
      component: ConnexionDev,
      beforeEnter: (to, from, next) => {
        if (LoginRequired(store) && isTherapeute(store)) {
          next("/utilisateurs");
        } else if (LoginRequired(store) && !isTherapeute(store)) {
          next("/activites");
        } else {
          next();
        }
      },
    },
    {
      path: "/validation-paiement",
      name: "ValidationPaiement",
      component: ValidationPaiement,
    },
    {
      path:"/newsletter-desinscription",
      name:"NewsletterDesinscription",
      component:NewsletterDesinscription,
    },
    {
      path: "/activites",
      name: "Accueil",
      component: Accueil,
      children: [
        {
          path: "new-abonnement",
          component: NewAbonnement,
          beforeEnter: (to, from, next) => {
            if (LoginRequired(store) && isTherapeute(store)) {
              if (CguRequired(store)) {
                next();
              } else {
                next("/mon-profil");
              }
            } else if (LoginRequired(store) && !isTherapeute(store)) {
              next("/activites");
            } else {
              next();
            }
          }
        },
      ]
    },
    {
      path: "/reinitialisation-mot-de-passe",
      name: "ResetPassword",
      component: ResetPassword,
      beforeEnter: (to, from, next) => {
        if (LoginRequired(store)) {
          next("/accueil");
        } else {
          next();
        }
      },
    },
    {
      path: "/inscription",
      name: "Inscription",
      component: InscriptionCredits,
      beforeEnter: (to, from, next) => {
        next();
        /*if (LoginRequired(store)) {
          next("/accueil");
        } else {
          next();
        }*/
      },
    },
    {
      path: "/inscription-credits",
      name: "inscriptionCredits",
      component: InscriptionCredits,
      beforeEnter: (to, from, next) => {
        next();
        /*if (LoginRequired(store)) {
          next("/accueil");
        } else {
          next();
        }*/
      },
    },
    {
      path: "/inscription-iframe",
      name: "InscriptionIframe",
      component: InscriptionCredits,
      // beforeEnter: (to, from, next) => {
      //   if (LoginRequired(store)) {
      //     next("/accueil");
      //   } else {
      //     next();
      //   }
      // }
    },
    {
      path: "/inscription-essai-pulse",
      name: "inscriptionCredits",
      component: InscriptionCredits,
      beforeEnter: (to, from, next) => {
        next();
        /*if (LoginRequired(store)) {
          next("/accueil");
        } else {
          next();
        }*/
      },
    },
    {
      path: "/header-test",
      name: "HeaderTest",
      component: HeaderTest,
    },
    {
      path: "/inscription-succeed",
      name: "InscriptionSucceed",
      component: InscriptionSucceed,
      beforeEnter: (to, from, next) => {
        next();
        /*if (LoginRequired(store)) {
          next("/accueil");
        } else {
          next();
        }*/
      },
    },
    {
      path: "/inscription-offre-play",
      name: "InscriptionOffrePlay",
      component: InscriptionOffrePlay,
      beforeEnter: (to, from, next) => {
        next();
        /*if (LoginRequired(store)) {
          next("/accueil");
        } else {
          next();
        }*/
      },
    },
    {
      path: "/inscription-offre-pulse",
      name: "InscriptionOffrePulse",
      component: InscriptionOffrePulse,
      beforeEnter: (to, from, next) => {
        next();
        /*if (LoginRequired(store)) {
          next("/accueil");
        } else {
          next();
        }*/
      },
    },
    {
      path: "/account-confirm",
      name: "AccountConfirm",
      component: AccountConfirm,
      beforeEnter: (to, from, next) => {
        if (LoginRequired(store)) {
          next("/accueil");
        } else {
          next();
        }
      },
    },
    {
      path: "/visio-confirm",
      name: "VisioConfirm",
      component: VisioConfirm,
      beforeEnter: (to, from, next) => {
        if (LoginRequired(store)) {
          next("/accueil");
        } else {
          next();
        }
      },
    },
    {
      path: "visio-refuse",
      name: "VisioRefuse",
      component: VisioRefuse,
      beforeEnter: (to, from, next) => {
        if (LoginRequired(store)) {
          next("/accueil");
        } else {
          next();
        }
      },
    },
    {
      path: "/accept-suivi",
      name: "AcceptSuivi",
      component: AcceptSuivi,
      beforeEnter: (to, from, next) => {
        if (LoginRequired(store)) {
          next("/accueil");
        } else {
          next();
        }
      },
    },
    {
      path:'/message-notification',
      name:'MessageNotification',
      component:MessageNotification,
      beforeEnter: (to, from, next) => {
        if (LoginRequired(store)) {
          next("/accueil");
        } else {
          next();
        }
      }
    },
    {
      path: "/",
      name: "Root",
      component: Root,
      children: [
        {
          path: "/",
          component: Home,
          beforeEnter: (to, from, next) => {
            if (LoginRequired(store) && isTherapeute(store)) {
              if (CguRequired(store)) {
                next();
              } else {
                next("/mon-profil");
              }
            } else if (LoginRequired(store) && !isTherapeute(store)) {
              next("/activites");
            } else {
              next();
            }
          },
        },
        {
          path: "/accueil",
          component: Utilisateurs,
          beforeEnter: (to, from, next) => {
            if (LoginRequired(store) && isTherapeute(store)) {
              if (CguRequired(store)) {
                next();
              } else {
                next("/mon-profil");
              }
            } else if (LoginRequired(store) && !isTherapeute(store)) {
              next("/activites");
            } else {
              next();
            }
          },
        },
        {
          path: "mon-espace",
          component: MonEspace,
          beforeEnter: (to, from, next) => {
            if (LoginRequired(store) && isTherapeute(store)) {
              if (CguRequired(store)) {
                next();
              } else {
                next("/mon-profil");
              }
            } else if (LoginRequired(store) && !isTherapeute(store)) {
              next("/activites");
            } else {
              next();
            }
          },
        },
        {
          path: "mes-activites",
          component: Activites,
          beforeEnter: (to, from, next) => {
            if (LoginRequired(store) && isTherapeute(store)) {
              if (CguRequired(store)) {
                next();
              } else {
                next("/mon-profil");
              }
            } else if (LoginRequired(store) && !isTherapeute(store)) {
              next("/activites");
            } else {
              next();
            }
          },
        },
        {
          path: "tableau-de-bord/:username",
          name: "suiviCCUtilisateur",
          component: TableauDeBord,
          beforeEnter: (to, from, next) => {
            if (!DroitAcces(store)) {
              next("/accueil");
              return;
            }
            if (LoginRequired(store) && isTherapeute(store)) {
              if (CguRequired(store)) {
                next();
              } else {
                next("/mon-profil");
              }
            } else if (LoginRequired(store) && !isTherapeute(store)) {
              next("/activites");
            } else {
              next();
            }
          },
        },
        {
          path: "tableau-de-bord/",
          component: TableauDeBord,
          beforeEnter: (to, from, next) => {
            if (!DroitAcces(store)) {
              next("/accueil");
              return;
            }
            if (LoginRequired(store) && isTherapeute(store)) {
              if (CguRequired(store)) {
                next();
              } else {
                next("/mon-profil");
              }
            } else if (LoginRequired(store) && !isTherapeute(store)) {
              next("/activites");
            } else {
              next();
            }
          },
        },
        {
          path: "/utilisateurs",
          component: Utilisateurs,
          beforeEnter: (to, from, next) => {
            if (!DroitAcces(store)) {
              next();
              return;
            }
            if (LoginRequired(store) && isTherapeute(store)) {
              if (CguRequired(store)) {
                next();
              } else {
                next("/mon-profil");
              }
            } else if (LoginRequired(store) && !isTherapeute(store)) {
              next("/activites");
            } else {
              next();
            }
          },
        },
        {
          path: "actualites",
          component: Actualites,
          beforeEnter: (to, from, next) => {
            if (!DroitAcces(store)) {
              next("/accueil");
              return;
            }
            if (LoginRequired(store) && isTherapeute(store)) {
              if (CguRequired(store)) {
                next();
              } else {
                next("/mon-profil");
              }
            } else if (LoginRequired(store) && !isTherapeute(store)) {
              next("/activites");
            } else {
              next();
            }
          },
        },
        {
          path: "seance-groupe",
          component: SeanceGroupe,
          beforeEnter: (to, from, next) => {
            if (!DroitAcces(store)) {
              next("/accueil");
              return;
            }
            if (LoginRequired(store) && isTherapeute(store)) {
              if (CguRequired(store)) {
                next();
              } else {
                next("/mon-profil");
              }
            } else if (LoginRequired(store) && !isTherapeute(store)) {
              next("/activites");
            } else {
              next();
            }
          },
        },
        {
          path: "retrocession-et-royalties",
          component: Retrocession,
          beforeEnter: (to, from, next) => {
            if (LoginRequired(store) && isTherapeute(store)) {
              if (CguRequired(store)) {
                next();
              } else {
                next("/mon-profil");
              }
            } else if (LoginRequired(store) && !isTherapeute(store)) {
              next("/activites");
            } else {
              next();
            }
          },
        },

        {
          path: "abonnement",
          component: Abonnement,
          beforeEnter: (to, from, next) => {
            if (LoginRequired(store) && isTherapeute(store)) {
              if (CguRequired(store)) {
                next();
              } else {
                next("/mon-profil");
              }
            } else if (LoginRequired(store) && !isTherapeute(store)) {
              next("/activites");
            } else {
              next();
            }
          },
        },
        {
          path: "mon-profil",
          component: MonProfil,
        },
        {
          path: "mon-ani",
          component: MonAni,
        },
        {
          path:"ma-messagerie",
          component: Messagerie,
        },
        {
          path:"personnalisation",
          component:Activites,
        },
        {
          path: "support",
          component: Support,
        },
        {
          path: "ma-configuration",
          component: MaConfiguration,
        },
        {
          path: "mes-visios",
          component: meetingComponent,
        },
        {
          path:"forum",
          component: Forum,
        }
      ],
      beforeEnter: (to, from, next) => {
        if (LoginRequired(store)) {
          next();
        } else {
          next("/connexion");
        }
      },
    },
  ],
});


export default router;
